<script setup lang="ts">
const props = defineProps<{
  data?: any
}>()

const viewport = useViewport()

const url = useRequestURL()

const { t, locale } = useI18n()
</script>

<template>
  <div v-if="props?.data?.length" class="w-full lg:w-68 md:w-60 shrink-0 max-h-64 lg:max-h-none overflow-y-auto hidden lg:block">
    <h4 class="px-4 text-sm font-semibold uppercase mb-4">
      {{ t('category') }}
    </h4>
    <ul class="mb-8">
      <li
        v-for="(item, index) in props?.data"
        :key="`mVmLaV${index}`"
      >
        <NuxtLinkLocale :to="item?.slug ? `/${item?.slug}` : '/'" :hreflang="locale" class="px-4 py-2 flex items-center justify-between hover:bg-gray-100 rounded-xl ">
          <span class="text-secondary text-base">{{ item?.label }}</span>
          <span v-if="parseInt(item?.count)" class="flex justify-center items-center h-6 w-6 text-xs text-secondary bg-gray-50 rounded">{{ item?.count }}</span>
        </NuxtLinkLocale>
      </li>
    </ul>

    <div v-if="viewport.isGreaterThan('tablet')">
      <SupportSocial />

      <QrCode
        v-if="url.href"
        :data="url.href"
        img-class="md:h-52 md:w-52"
        caption-class="w-full mx-auto"
      />
    </div>
  </div>
</template>

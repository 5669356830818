<script setup lang="ts">
const props = defineProps<{
  data?: any
}>()

const { t } = useI18n()
const localePath = useLocalePath()

const isOpen = ref(false)

const selected = ref<any>({})

async function onSelectCategory(option: any) {
  isOpen.value = false
  await navigateTo(option?.slug ? localePath(`/${option?.slug}`) : localePath('/'))
}
</script>

<template>
  <div v-if="props?.data?.length" class="mx-auto max-w-screen-xl relative block lg:hidden text-center px-2 xl:px-0 mb-8">
    <UButton
      block
      :label="t('category')"
      color="gray"
      size="xl"
      @click="isOpen = true"
    >
      <template #trailing>
        <UIcon name="i-heroicons-arrow-right-20-solid" />
      </template>
    </UButton>

    <UModal v-model="isOpen">
      <UCommandPalette
        v-model="selected"
        nullable
        :autoselect="false"
        :groups="[{ key: 'people', commands: props?.data }]"
        :empty-state="{ icon: 'i-heroicons-magnifying-glass-20-solid', label: t('no_items'), queryLabel: t('no_items_try') }"
        :placeholder="t('type_something')"
        :ui="{
          input: {
            size: 'sm:text-xl',
          },
          emptyState: {
            label: 'text-xl',
            queryLabel: 'text-xl',
          },
          group: {
            label: 'text-xl',
            container: 'text-xl',
            command: {
              base: 'py-2.5',
            },
          },
        }"
        @update:model-value="onSelectCategory"
      />
    </UModal>
  </div>
</template>
